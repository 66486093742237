import * as React from "react"
import {Card, Container} from "react-bootstrap";
import Layout from "../components/layout";
import {Link} from "gatsby";

// markup
const NotFoundPage = () => {
  return <Layout>
    <Container>
      <h1 className={'my-5'}>Seite nicht gefunden</h1>
      <Card className={'shadow p-3 meta-content py-5'}>
        <Card.Body>
          <h2 className="display-5 text-center py-5">
            Die von Ihnen angegebene URL existiert nicht.
          </h2>
          <p className={'mb-5 text-center'}>
            <Link to={'/'} className="btn btn-lg btn-secondary">Zur Startseite</Link>
          </p>
        </Card.Body>
      </Card>
    </Container>
  </Layout>
}

export default NotFoundPage
